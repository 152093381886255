import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from "../assets/images/timeline/logo1.jpg";
import { useForm } from "react-hook-form";

const Register = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {

    console.log(data);

    try {
      const res = await axios.post(
        "https://siw-student-from.onrender.com/api/siw/register",
        data
      );
      const datasets = res.data.status;
      if (datasets === 201) {
        toast.success("Registration is successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        
        reset();
        navigate("/"); // Navigate to home page on successful registration

      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 400) {
          toast.error("Email already exists");
        } else if (statusCode === 405) {
          toast.error("Mobile number already exists");
        } else {
          toast.error(error.response.data.message);
        }
      } else if (error.request) {
        toast.error("Network Error: Please check your internet connection");
      } else {
        toast.error("An unexpected error occurred");
      }
    }

    // const [user, setUser] = useState({
    //   name: "",
    //   email: "",
    //   number: "",
    //   password: "",
    // });
    // let name, value;

    // const handleChange = (e) => {
    //   name = e.target.name;
    //   value = e.target.value;
    //   setUser({
    //     ...user,
    //     [name]: value,
    //   });
    // };
    // const navigate = useNavigate();

    // const handleRegister = async (e) => {
    //   e.preventDefault();
    //   const { name, email, number, password } = user;
    //   console.log(user)

    //     const data = await axios.post("http://localhost:5000/api/user/register", user);
    //     console.log(data)

    // if (data.data.status === 2) {
    //   toast.warn(data.data.massege);
    // } else if (data.status === 200) {
    //   toast.success(data.data.massege);
    //   navigate("/");
    // }
  };

  return (
    <>
      <section className="h-100 gradient-form background-color">
        <div className="container py-2 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-xl-10">
              <div className="card rounded-3 text-black">
                <div className="row g-0 background-color">
                  <div className="col-lg-6 background-color-box">
                    <div className="card-body mx-md-4">
                      <div className="text-center">
                        <img src={Logo} alt="logo" className="img-log" />
                        <h4 className="mt-1 mb-5 pb-1 text-color">
                          We are The Lotus Team
                        </h4>
                      </div>

                      <form onSubmit={handleSubmit(onSubmit)}>
                        <p className="text-color">
                          Please Register to your account
                        </p>
                        <label
                          className="form-label text-color"
                          for="form2Example11"
                        >
                          Full Name :-
                        </label>
                        <div className="form-outline inputboder mb-4">
                          <input
                            className="form-control"
                            type="text"
                            // value={user.name}
                            // onChange={handleChange}
                            // name="name"
                            {...register("name", {
                              required: "Name is required",
                            })}
                          />
                          {errors.name && (
                            <div className="text-danger">
                              {errors.name.message}
                            </div>
                          )}
                        </div>
                        <label
                          className="form-label text-color"
                          for="form2Example22"
                        >
                          Email ID :-
                        </label>
                        <div className="form-outline inputboder mb-4">
                          <input
                            className="form-control"
                            type="email"
                            // value={user.email}
                            // name="email"
                            // onChange={handleChange}
                            {...register("email", {
                              required: "Email is required",
                            })}
                          />
                          {errors.email && (
                            <div className="text-danger">
                              {errors.email.message}
                            </div>
                          )}
                        </div>

                        <label
                          className="form-label text-color"
                          for="form2Example22"
                        >
                          Mobile Number
                        </label>
                        <div className="form-outline inputboder mb-4">
                          <input
                            className="form-control"
                            type="number"
                            // value={user.mobileNumber}
                            // onChange={handleChange}
                            // name="number"
                            {...register("number", {
                              required: "Mobile Number is required",
                            })}
                          />
                          {errors.number && (
                            <div className="text-danger">
                              {errors.number.message}
                            </div>
                          )}
                        </div>

                        <label
                          className="form-label text-color"
                          for="form2Example22"
                        >
                          Password:-
                        </label>
                        <div className="form-outline inputboder mb-4">
                          <input
                            className="form-control"
                            type="password"
                            // value={user.password}
                            // onChange={handleChange}
                            // name="password"
                            {...register("password", {
                              required: "Password is required",
                            })}
                          />
                          {errors.password && (
                            <div className="text-danger">
                              {errors.password.message}
                            </div>
                          )}
                        </div>

                        <div className="text-center pt-1 mb-5 pb-1">
                          <button
                            className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3 fontfamily gradient-custom-2"
                            type="submit"
                          >
                            Log in
                          </button>
                        </div>

                        <div className="d-flex align-items-center justify-content-center pb-4">
                          <p className="mb-0 me-2 text-color">
                            Don't have an account?
                          </p>
                          <button
                            type="button"
                            className="btn  fontfamily ml-4"
                          >
                            <Link
                              to="/"
                              style={{
                                color: "#d8363a",
                                textDecoration: "none",
                              }}
                            >
                              Login
                            </Link>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h4 className="mb-4">We are more than just a company</h4>
                      <p className="small mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Register;
