import React from "react";

import Dashboard from "./Dashboard";
import Footer from "./Common/Footer";

const Home = () => {
  return (
    <>
      <Dashboard />
      <Footer />
    </>
  );
};
export default Home;
